<template>
    <div class="grid">
      <Toast />
      <div class="col-12">
        <div class="card">
          <Accordion :multiple="true">
            <AccordionTab header="Guia rápido de consulta">
              <p>
                Os
                <strong
                  ><u
                    >eventos de risco legal constituem um tipo de risco
                    operacional</u
                  ></strong
                >
                e devem, <strong><u>como regra</u></strong
                >, seguir as definições e procedimento geral de registro de
                eventos no documento 5050 (§ 1º da Resolução nº 4.577).
              </p>
              <p>
                Hoje, devem ser consideradas apenas as
                <u>contingências judicias passivas</u>:
              </p>
              <ul>
                <li>
                  Eventos trabalhistas: estão relacionados à resolução de litígios
                  envolvendo a legislação trabalhistas e condições de trabalho.
                </li>
                <li>
                  Eventos Cíveis: relacionam-se aos demais pleitos movidos contra
                  à entidade, desde relações com clientes, fornecedores ou
                  terceiros. Sua categorização específica será determinada com
                  base na alegação subjacente do pleito.
                </li>
                <li>
                  Eventos Fiscais: litígios perante as autoridades fiscais
                  relativos a tributos devidos pela entidade, seja como
                  contribuinte ou responsável tributário. Abrangem o valor
                  principal do tributo não pago, as multas, os juros incidentes,
                  as penalidades e custos relacionados ao não pagamento ou
                  pagamento em atraso.
                </li>
              </ul>
            </AccordionTab>
            <AccordionTab header="O que é Risco Operacional?">
                texto texto texto
            </AccordionTab>
            <AccordionTab header="Eventos de risco são perdas operacionais?">
                texto texto texto
            </AccordionTab>
            <AccordionTab
              header="Quando este evento de risco legal deve ser adicionado a base?"
            >
              No momento em que for reconhecida uma “despesa” relativa à esse
              evento.
            </AccordionTab>
            <AccordionTab header="E o que pode ser entendido como despesa?">
              <p>
                Um
                <u>dispêndio financeiro ou um reconhecimento de um provisão.</u>
              </p>
              <p>
                Além disso,
                <u
                  >os pagamentos e/ou provisionamento de honorários advocatícios
                  e/ou custas processuais </u
                >também&nbsp;configuram perdas operacionais e devem ser lançados
                na base nas colunas especificas.
              </p>
              <p>
                Essa definição inclui os honorários de sucesso vinculados a uma
                cláusula de êxito, que ocorre quando, por exemplo, um advogado
                externo consegue reduzir um valor elevado de perda a um valor
                substancialmente menor, cobrando um percentual sobre a diferença
                pleiteada incialmente e o valor efetivamente pago.
              </p>
              <p>
                Custos com advogados internos (ex.: departamento jurídico) ou
                custos fixos externos que não podem ser atribuídos a evento
                específico não deverão compor valores de perda no Documento 5050.
              </p>
              <p>
                <strong><em>Vale destacar que:</em></strong>
              </p>
              <ol>
                <li>
                  <em
                    >Para as contingências judiciais passivas em que não há
                    despesas incorridas e não houve necessidade de constituição de
                    provisões, tais como os processos analisados individualmente e
                    classificados como risco de perda remoto ou possível,
                    requer-se o registro no Documento 5050 da informação do “valor
                    do risco” dessa contingência, tal como existir no sistema
                    legado onde são avaliadas as contingências.</em
                  >
                </li>
                <li>
                  <em
                    >Eventuais ressarcimentos relativos à perda legal, decorrentes
                    de cláusulas de reembolso em acordo de aquisição, cisão ou
                    decorrentes de seguro, serão registrados na forma de
                    recuperação. O mesmo vale para custas processuais inicialmente
                    pagas pela instituição e posteriormente atribuídos à
                    contraparte por decisão judicial (como o ônus de
                    sucumbência).</em
                  >
                </li>
                <li>
                  <em
                    >Obrigações legais não são consideradas perdas
                    operacionais</em
                  >
                </li>
              </ol>
              <p><br /></p>
            </AccordionTab>
            <AccordionTab header="O que são provisões massificadas?">
              <p>
                São processos relativos a causas similares, cuja provisão é obtida
                a partir de metodologia de cálculo estatístico que considere o
                tipo de causa como um todo, conforme preconizado pelo CPC-25,
                itens 24 e 39.
              </p>
              <p>
                Respeitadas as diferenças entre os grupos de causas, podem ser
                aplicadas a qualquer natureza de contingência judicial, sendo mais
                comum nas esferas trabalhista e cível.
              </p>
              <p>
                No Documento 5050 devem ser identificados os eventos de
                contingências judiciais passivas cuja estimativa de provisão faz
                uso de métodos massificados, diferenciando dos casos de
                contingências que são avaliados individualmente.
              </p>
              <p>
                Também devem ser registrados individualmente em cada evento o
                valor da provisão obtido pela metodologia de tratamento
                massificado, ainda que sejam valores idênticos para todo o grupo
                de causas similares.
              </p>
              <p>
                Nos casos em que um evento informado individualmente cuja
                avaliação era feita de forma massificada (campo tipoAvaliacao
                igual a “M”) passe a ser avaliado de forma individual (campo
                tipoAvaliacao igual a “I”), nos termos do CPC-25, este evento
                deverá ter seu preenchimento devidamente adequado para a nova
                forma de avaliação, inclusive passando a informar os campos
                relativos a probabilidade de perda e valor em risco.
              </p>
            </AccordionTab>
            <AccordionTab header="Links úteis">
              <p>
                Circular BCB nº 3.979:
                <a
                  href="https://www.bcb.gov.br/pre/normativos/busca/downloadNormativo.asp?arquivo=/Lists/Normativos/Attachments/50913/Circ_3979_v1_O.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                  >https://www.bcb.gov.br/pre/normativos/busca/downloadNormativo.asp?arquivo=/Lists/Normativos/Attachments/50913/Circ_3979_v1_O.pdf</a
                >
              </p>
              <p>
                Resolução BCB nº 4.557:
                <a
                  href="https://www.bcb.gov.br/pre/normativos/busca/downloadNormativo.asp?arquivo=/Lists/Normativos/Attachments/50344/Res_4557_v1_O.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                  >https://www.bcb.gov.br/pre/normativos/busca/downloadNormativo.asp?arquivo=/Lists/Normativos/Attachments/50344/Res_4557_v1_O.pdf</a
                >
              </p>
              <p>
                Leiaute do documento 5050:
                <a
                  href="https://www.bcb.gov.br/content/estabilidadefinanceira/leiautedocumento5050_docs/DRO%20-%20Demonstrativo%20de%20Risco%20Operacional%20-%20Instru%C3%A7%C3%B5es%20de%20Preenchimento.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                  >https://www.bcb.gov.br/content/estabilidadefinanceira/leiautedocumento5050_docs/DRO%20-%20Demonstrativo%20de%20Risco%20Operacional%20-%20Instru%C3%A7%C3%B5es%20de%20Preenchimento.pdf</a
                >
              </p>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
      };
    },
    watch: {},
    methods: {
      
    },
  };
  </script>
  
  <style>
  </style>